export const exportColumn = [
    {
      title: "供应商名称",
      key: "compName"
    },
    {
      title: "物料名称",
      key: "goodsName"
    },
    {
        title: "车牌号",
        key: "licensePlate"
      },
      {
        title: "车牌颜色",
        key: "plateColor"
      },
      {
        title: "司机姓名",
        key: "driverName"
      },
      {
        title: "司机手机号",
        key: "driverMobile"
      },
      {
        title: "过毛时间",
        key: "grossTime"
      },
      {
        title: "过皮时间",
        key: "tareTime"
      },
      {
        title: "验收人确认",
        key: "checkOperateName"
      },
      {
        title: "卸货人确认",
        key: "unLoadOperateName"
      },  
      {
        title: "毛重",
        key: "grossWeight"
      }, 
      {
        title: "皮重",
        key: "tareWeight"
      }, 
      {
        title: "净重",
        key: "netWeight"
      }
    ]